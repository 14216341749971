<template>
  <!-- <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    :scrollable="$vuetify.breakpoint.smAndDown"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  > -->

  <v-navigation-drawer
    right
    class="navigation-drawer height-app"
    :value="dialog"
    app
    persistent
    :width="bestWidth > 600 ? 450 : bestWidth"
    :height="this.best_height"
  >
    <template v-slot:prepend>
      <v-toolbar>
        <v-toolbar-title>{{ $store.getters.company.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('close', true)"> mdi-close </v-icon>
      </v-toolbar>
    </template>
    <template v-slot:default>
      <v-img :src="invoiceImg"></v-img>
    </template>

    <template v-slot:append>
      <v-bottom-navigation>
        <!-- <v-btn color="success" @click="e_invoice()"> Invoice </v-btn> -->

        <v-btn @click="print_pdf()">
          <span>PDF</span>
          <v-icon>mdi-cloud-download-outline</v-icon>
        </v-btn>
        <!-- <v-btn color="success" @click="get_e_doc()"> Anular </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn @click="print_doc()">
          <span>Print</span>
          <v-icon> mdi-printer-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </template>
    <!-- <v-card>
            <v-card-text>
        <iframe :src="pdfFile" width="100%" height="800" frameborder="0"> </iframe>
        
      </v-card-text>
      <v-card-actions class="pa-0">
        
      </v-card-actions>
    </v-card> -->
  </v-navigation-drawer>
</template>

<script>
import { fe_ws } from "../services/webserver.js";
import docs_lst from "../utils/docs.js";
import createDoc from "../utils/create_doc.js";
import dianResponse from "../utils/dianResponse.js";
import { webserver } from "../services/webserver.js";
import dianSettings from "../utils/settings.js";
import jsPDF from "jspdf";
var QRCode = require("qrcode");
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: createDoc(),
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
    this.company = this.$store.getters.company;
  },
  data() {
    return {
      bestWidth: window.innerWidth,
      best_height: "100%",
      settings: dianSettings(),
      docTypes: docs_lst().docType,
      invoiceImg: null,
      dian_dialog: false,
      dian_response: dianResponse(),
      docview: "",
      loading_status: false,
      pdfFile: null,
    };
  },
  methods: {
    onResize() {
      this.best_height = window.innerHeight;
      this.bestWidth = window.innerWidth;
    },
    print_doc() {
      var a = window.open("", "", "height=600, width=400");
      a.document.write('<img src="' + this.dataURL + '"  width="100%">');
      a.print();
      a.document.close();
      setTimeout(() => {
        a.close();
      }, 500);
    },
    print_canvas(data) {
      //console.log(data);
      var LS = 50;
      var itemsQty = data.items.length * LS;
      var elem = document.createElement("canvas");
      elem.width = 945;
      elem.height = 1800 + itemsQty;
      var total = 0;
      var center = elem.width / 2;
      var left = 10;
      var line = 150;
      var right = elem.width - left;
      const ctx = elem.getContext("2d");

      var items = data.items;
      items.forEach((item) => {
        item.qty = parseInt(item.qty);
        item.price = parseInt(item.price);
        item.discount = parseInt(item.discount);
      });
      var regimen = "NO RESPONSABLES DEL IVA";

      if (data.store.TaxLevelCode) {
        switch (data.store.TaxLevelCode) {
          case "0":
            regimen = "NO RESPONSABLES DEL IVA";
            break;
          case "1":
            regimen = "RESPONSABLES DEL IVA";
            break;
          default:
            regimen = "NO RESPONSABLES DEL IVA";
        }
      }

      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";

      ctx.font = "bold 60px Arial";
      ctx.fillText(data.header.doc_type, center, line);
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.fillText(data.store.name, center, line);
      line += LS;
      ctx.fillText("Nit : " + data.store.identification_id, center, line);
      line += LS;
      ctx.fillText(regimen, center, line);
      line += LS;
      ctx.fillText(data.store.AddressLine + ", " + data.store.CityName, center, line);
      // line += LS;
      // ctx.fillText("Resolución DIAN: " + data.permit.InvoiceAuthorization, center, line);
      line += LS;
      // ctx.fillText(
      //   "Del: " +
      //     data.permit.Prefix +
      //     "-" +
      //     data.permit.AuthorizationFrom +
      //     " al " +
      //     data.permit.Prefix +
      //     "-" +
      //     data.permit.AuthorizationTo,
      //   center,
      //   line
      // );
      // line += LS;
      // ctx.fillText(
      //   "Desde: " + data.permit.StartDate + " al " + data.permit.EndDate,
      //   center,
      //   line
      // );
      line += LS;
      ctx.font = "bold 35px Arial";
      ctx.fillText("Documento Equivalente a la Factura de Venta", center, line);
      ctx.font = "bold 40px Arial";
      line += LS;
      ctx.fillText("No. " + data.header.invoice, center, line);
      line += LS;
      ctx.fillText("NO SOMOS GRANDES CONTRIBUYENTES", center, line);
      line += LS;
      ctx.fillText("NO RETENEDORES DE IVA", center, line);
      line += LS;
      ctx.fillText("Sistema POS", center, line);
      line += LS;
      ctx.fillText(data.header.issue_date, center, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("C.C./Nit : " + data.header.party_identification_id, left, line);
      line += LS;
      ctx.fillText("Nombre : " + data.header.party_name, left, line);
      line += LS;
      line += LS;
      LS = 60;
      ctx.font = "bold 50px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Productos", center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      total = 0;
      var tdiscount = 0;
      items.forEach((row) => {
        //console.log(row);
        var price = row.price * row.quantity;
        var discount = row.discount * row.quantity;
        ctx.textAlign = "start";
        ctx.fillText(row.qty, left, line);
        ctx.textAlign = "start";
        ctx.fillText(row.product_name, left + 50, line);
        ctx.textAlign = "end";
        ctx.fillText(row.price.toLocaleString("es"), right, line);
        line += LS;
        total += price;
        tdiscount += discount;
      });
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      var tax_value = 1.19;
      var sin_iva = Math.round(total / tax_value);
      var iva = total - sin_iva;
      ctx.textAlign = "start";
      ctx.fillText("Base gravable", left, line);
      ctx.textAlign = "end";
      ctx.fillText(sin_iva.toLocaleString(), right, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("IVA " + Math.round((tax_value - 1) * 100) + "%", left, line);
      ctx.textAlign = "end";
      ctx.fillText(iva.toLocaleString(), right, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("TOTAL", left, line);
      ctx.textAlign = "end";
      ctx.fillText(total.toLocaleString(), right, line);
      line += LS;

      if (tdiscount > 0) {
        ctx.textAlign = "start";
        ctx.fillText("Descuento aplicado", left, line);
        ctx.textAlign = "end";
        ctx.fillText("( " + tdiscount.toLocaleString() + " )", right, line);
        line += LS;
      }
      line += LS;

      ctx.textAlign = "center";
      ctx.fillText("Formas de pago", center, line);
      line += LS;
      // console.log(data.payments);
      // data.payments.forEach((row) => {
      //   console.log(row);
      //   ctx.textAlign = "start";
      //   ctx.fillText(row.tipo + " " + row.comprobante, left, line);
      //   ctx.textAlign = "end";
      //   ctx.fillText(row.valor.toLocaleString(), right, line);
      //   line += LS;
      // });
      line += LS;
      ctx.textAlign = "center";
      // if (window.store.store_footer) {
      //   var res = window.store.store_footer.split("<br>");
      //   res.forEach((item) => {
      //     ctx.fillText(item, center, line);
      //     line += LS;
      //   });
      // } else {
      ctx.fillText("! Gracias por su compra !", center, line);
      // }
      line += LS;
      line += LS;
      line += LS;

      line += LS;
      line += LS;

      this.dataURL = elem.toDataURL();
      this.invoiceImg = this.dataURL;
      this.showPrint = true;
    },
    printCanvas(data) {
      //console.log(data);

      var docInfo = this.docTypes.find((o) => o.value === data.header.doc_type);

      data.seller = data.store;
      data.document = data.header;
      data.company = this.company;
      data.buyer = data.header;
      data.permit = {
        InvoiceAuthorization: null,
        Prefix: null,
        AuthorizationFrom: null,
        AuthorizationTo: null,
        StartDate: null,
        EndDate: null,
      };
      var url_cufe =
        "https://catalogo-vpfe-hab.dian.gov.co/document/searchqr?documentkey=";

      if (data.company.environment === "1") {
        url_cufe = "https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=";
      }

      var LS = 40;
      var itemsQty = data.items.length * LS;
      var elem = document.createElement("canvas");
      elem.width = 945;
      elem.height = 3000 + itemsQty * 3;
      var total = 0;
      var center = elem.width / 2;
      var left = 10;
      var line = 150;
      var right = elem.width - left;

      const ctx = elem.getContext("2d");

      var items = data.items;
      items.forEach((item) => {
        item.quantity = parseFloat(item.quantity);
        item.price = parseFloat(item.price);
        item.discount = parseFloat(item.discount);
        item.tax_base = parseFloat(item.tax_base);
        item.tax_value = parseFloat(item.tax_value);
        item.tItem = parseFloat(item.tItem);
      });
      var regimen = "NO RESPONSABLES DEL IVA";

      if (data.seller.TaxLevelCode) {
        switch (data.seller.TaxLevelCode) {
          case "0":
            regimen = "NO RESPONSABLES DEL IVA";
            break;
          case "1":
            regimen = "RESPONSABLES DEL IVA";
            break;
          default:
            regimen = "NO RESPONSABLES DEL IVA";
        }
      }

      ctx.font = "bold 45px Arial";
      ctx.textAlign = "center";
      ctx.fillText(data.seller.name, center, line);
      line += LS;
      ctx.font = "bold 35px Arial";
      ctx.fillText(data.seller.group_name, center, line);
      line += LS;
      ctx.fillText(
        this.settings["type_document_identifications"].find(
          (item) => item.id == data.company.type_id
        ).name +
          " : " +
          data.company.identification_id,
        center,
        line
      );

      line += LS;

      ctx.fillText(
        data.company.address +
          ", " +
          this.settings["municipalities"].find((item) => item.code == data.company.city)
            .name,
        center,
        line
      );
      line += LS;
      line += LS;
      ctx.font = "bold 45px Arial";
      ctx.fillText(docInfo.label, center, line);
      ctx.font = "bold 35px Arial";
      if (data.header.invoice) {
        line += LS;
        ctx.fillText(regimen, center, line);
        line += LS;
        ctx.font = "bold 35px Arial";
        ctx.fillText("NO SOMOS GRANDES CONTRIBUYENTES", center, line);
        line += LS;
        ctx.fillText("NO RETENEDORES DE IVA", center, line);
        line += LS;
        ctx.font = "bold 40px Arial";
        line += LS;
        ctx.fillText(
          "Resolución DIAN: " + data.permit.InvoiceAuthorization,
          center,
          line
        );
        line += LS;
        ctx.fillText(
          "Del: " +
            data.permit.Prefix +
            "-" +
            data.permit.AuthorizationFrom +
            " al " +
            data.permit.Prefix +
            "-" +
            data.permit.AuthorizationTo,
          center,
          line
        );
        line += LS;
        ctx.fillText(
          "Desde: " + data.permit.StartDate + " al " + data.permit.EndDate,
          center,
          line
        );
        line += LS;
        ctx.fillText("No. " + data.header.invoice, center, line);
      } else {
        line += LS;
        ctx.fillText("No. " + data.header.doc_code, center, line);
      }

      line += LS;
      ctx.fillText(data.document.issue_date, center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      // if (data.payments[0].hora) {

      // } else {
      //   ctx.fillText(data.document.issueDate, center, line);
      // }

      line += LS;
      ctx.textAlign = "start";
      var PartyTypeId = "";

      if (data.buyer.party_type_id) {
        PartyTypeId = this.settings["type_document_identifications"].find(
          (item) => item.id == data.header.party_type_id
        ).name;
      }
      ctx.fillText(PartyTypeId + " : " + data.header.party_identification_id, left, line);
      line += LS;
      ctx.font = "normal 25px Arial";
      ctx.fillText("Nombre / Razón Social: ", left, line);
      ctx.font = "bold 40px Arial";
      line += LS;
      ctx.fillText(data.header.party_name, left, line);
      line += LS;
      ctx.fillText("Email     : " + data.header.party_email, left, line);

      line += LS;
      line += LS;
      LS = 40;
      ctx.font = "bold 35px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Productos", center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      total = 0;
      var taxes = 0;
      var baseTaxes = 0;
      var tdiscount = 0;
      var idx = 1;
      ctx.textAlign = "start";
      ctx.fillText("Item", left, line);
      ctx.textAlign = "start";
      ctx.fillText("Detalle", left + 120, line);
      ctx.textAlign = "end";
      ctx.fillText("Cant.", right - 200, line);
      ctx.textAlign = "end";
      ctx.fillText("Valor", right, line);

      line += LS;
      items.forEach((row) => {
        //console.log(row);
        ctx.textAlign = "start";
        ctx.fillText(idx, left, line);
        ctx.textAlign = "start";
        ctx.fillText(row.product_name, left + 50, line);

        ctx.textAlign = "end";
        ctx.fillText(row.qty, right - 200, line);
        ctx.textAlign = "end";
        ctx.fillText(row.tax_base.toLocaleString(), right, line);
        line += LS;
        ctx.font = " 35px Arial";
        ctx.textAlign = "start";
        ctx.fillText("Cod: " + row.code, left + 50, line);
        line += LS;
        idx += 1;

        if (row.discount_value > 0) {
          ctx.fillText(
            " IVA: $" +
              row.tax_value +
              " / Descuento: $" +
              row.discount_value.toLocaleString(),
            left + 50,
            line
          );
        } else {
          ctx.fillText(
            "IVA " + row.tax + "% :" + row.tax_value.toLocaleString(),
            left + 50,
            line
          );
        }
        line += LS;
        ctx.font = "bold 35px Arial";
        total += row.tItem;
        taxes += row.tax_value;
        baseTaxes += row.tax_base;
        tdiscount += row.discount_value;
      });
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;

      ctx.textAlign = "start";
      ctx.fillText("Base gravable", left, line);
      ctx.textAlign = "end";
      ctx.fillText(baseTaxes.toLocaleString(), right, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("IVA  19%", left, line);
      ctx.textAlign = "end";
      ctx.fillText(taxes.toLocaleString(), right, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("TOTAL", left, line);
      ctx.textAlign = "end";
      ctx.fillText(total.toLocaleString(), right, line);
      line += LS;

      if (tdiscount > 0) {
        ctx.textAlign = "start";
        ctx.fillText("Descuento aplicado", left, line);
        ctx.textAlign = "end";
        ctx.fillText("( " + tdiscount.toLocaleString() + " )", right, line);
        line += LS;
      }
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("Foma de pago: CONTADO", left, line);
      line += LS;
      ctx.textAlign = "center";
      ctx.fillText("Medio de pago", center, line);
      line += LS;
      //console.log(data.payments);
      data.payments.forEach((row) => {
        //console.log(row);
        ctx.textAlign = "start";
        ctx.fillText(row.payment_concept + " " + row.payment_reference, left, line);
        ctx.textAlign = "end";
        ctx.fillText(parseFloat(row.payment_value).toLocaleString(), right, line);
        line += LS;
      });
      line += LS;
      ctx.textAlign = "center";
      // if (window.seller.store_footer) {
      //   var res = window.seller.store_footer.split("<br>");
      //   res.forEach((item) => {
      //     ctx.fillText(item, center, line);
      //     line += LS;
      //   });
      // } else {
      ctx.fillText("! Gracias por su compra !", center, line);
      // }
      line += LS;

      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      ctx.font = " 35px Arial";

      ctx.textAlign = "center";

      ctx.fillText("Modo de Operacion  Software Propio", center, line);
      line += 35;

      ctx.fillText(data.seller.PartyName, center, line);
      line += 35;
      ctx.fillText(
        this.settings["type_document_identifications"].find(
          (item) => item.id == data.company.type_id
        ).name +
          " : " +
          data.company.PartyIdentification,
        center,
        line
      );
      line += 35;

      ctx.textAlign = "center";
      ctx.fillText("Software fabricado  por :", center, line);
      line += 35;
      ctx.fillText("NGM Corp. NIT otro país: 764763603RC001", center, line);
      line += 35;
      ctx.fillText("Nombre del Software POSWEB-", center, line);

      line += LS;
      if (data.document.cufe) {
        ctx.textAlign = "center";
        const result = data.document.cufe.match(/.{1,38}/g) ?? [];
        ctx.font = "normal 38px Arial";
        ctx.fillText("CUFE:", center, line);
        line += 35;
        result.forEach((itm) => {
          ctx.fillText(itm, center, line);
          line += 35;
        });

        QRCode.toDataURL(url_cufe + data.document.cufe, (err, url) => {
          this.urlCufe = url;
          line += 45;
        });

        if (data.document.cufe_nc) {
          const result_nc = data.document.cufe_nc.match(/.{1,38}/g) ?? [];
          ctx.font = "normal 38px Arial";
          ctx.fillText("CUFE NC:", center, line);
          line += 35;
          result_nc.forEach((itm) => {
            ctx.fillText(itm, center, line);
            line += 35;
          });
        }
      }
      line += LS;

      // ctx.fillText("Productos", center, line);
      line += LS;
      line += LS;

      line += LS;
      line += LS;

      this.dataURL = elem.toDataURL();
      this.invoiceImg = this.dataURL;
      this.showPrint = true;
    },
    print_pdf() {
      //console.log("----", doc);
      var doc = this.item;
      if (doc.header) {
        var pdf = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: "letter",
        });
        var LS = 5;
        var y = 20;
        var x = 20;

        pdf.setFontType = "bold";
        pdf.setFontSize(14);
        pdf.text(20, y, doc.store.name, "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(
          20,
          y,
          this.settings["type_document_identifications"].find(
            (item) => item.id == doc.store.type_id
          ).name +
            " " +
            doc.store.identification_id,
          "left"
        );
        y += LS;
        pdf.text(20, y, doc.store.address, "left");
        y += LS;
        pdf.text(20, y, doc.store.city + ", " + doc.store.province, "left");
        y = 48;

        pdf.setFontSize(8);
        pdf.text(
          20,
          y,
          this.docTypes.find((item) => item.value == doc.header.doc_type).label +
            " : " +
            doc.header.doc_code,
          "left"
        );
        pdf.text(160, y, "Fecha: " + doc.header.issue_date, "left");
        y += LS;
        pdf.text(160, y, "Estado: " + doc.header.status, "left");
        y += LS;
        y += 2;
        pdf.setLineWidth(0.1);
        pdf.setDrawColor(0, 102, 0);
        // pdf.line(20, y, 200, y);
        y += LS;
        y += LS;
        y = 20;
        pdf.setFontSize(14);
        pdf.text(120, y, doc.header.party_name, "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(120, y, doc.header.party_address, "left");
        y += LS;
        pdf.text(120, y, doc.header.party_city, "left");

        y += LS;
        pdf.text(
          120,
          y,
          doc.header.party_email + " Tel: " + doc.header.party_phone,
          "left"
        );
        y += LS;
        pdf.text(120, y, doc.header.party_note, "left");
        y += LS;
        y += LS;
        y = 60;
        pdf.setFontSize(10);

        pdf.setFontType = "normal";
        pdf.text(50, y, "Product", "center");
        pdf.text(130, y, "Qty", "center");
        pdf.text(150, y, "Pack", "center");
        pdf.text(170, y, "", "center");
        pdf.text(195, y, "Price", "center");
        // y += 2;
        // pdf.line(20, y, 200, y);
        var temY = y;
        pdf.roundedRect(15, temY - 5, 190, 140, 1, 1);
        doc.items.forEach((row) => {
          y += LS;
          pdf.setFontType = "bold";
          pdf.text(20, y, row.product_name);
          pdf.setFontType = "normal";
          pdf.text(130, y, row.qty.toLocaleString(), "center");
          pdf.text(
            150,
            y,
            parseFloat(row.pack).toLocaleString(2) + " " + row.uom,
            "center"
          );
          pdf.text(
            170,
            y,
            (parseFloat(row.qty) * parseFloat(row.pack)).toLocaleString(2) +
              " " +
              row.uom,
            "center"
          );
          pdf.text(200, y, "$ " + parseFloat(row.tItem).toLocaleString(2), "right");
          y += LS;
          var dtext = row.description.replace(/<[^>]+>/g, "");
          var splitTitle = pdf.splitTextToSize(dtext, 105);
          pdf.text(20, y, splitTitle);
          y += LS;
          //pdf.text(x, y, dtext);
        });

        y = 200;
        temY = y;
        pdf.setFillColor(255, 255, 200);
        pdf.roundedRect(15, y - 2, 190, 20, 1, 1);

        doc.payments.forEach((row) => {
          y += LS;
          x = 20;
          pdf.text(x, y, row.payment_concept, "left");
          x = 120;
          pdf.text(x, y, "$ " + parseFloat(row.payment_value).toLocaleString(2), "right");
          y += LS;
          x = 30;
          if (row.description) {
            var dtext = row.description.replace(/<[^>]+>/g, "");
            var splitTitle = pdf.splitTextToSize(dtext, 150);
            pdf.text(x, y, splitTitle);
            y += LS;
          }
        });
        y = temY;

        y += LS;
        x = 150;
        pdf.setFontType = "bold";
        pdf.text(x, y, "Sub-Total");
        x = 200;
        pdf.text(x, y, "$ " + parseFloat(doc.header.total).toLocaleString(2), "right");
        y += LS;
        x = 150;
        pdf.text(x, y, "Impuesto");
        x = 200;
        pdf.text(x, y, "$ " + parseFloat(doc.header.tax).toLocaleString(2), "right");
        y += LS;
        x = 150;
        pdf.text(x, y, "TOTAL");
        x = 200;
        pdf.text(
          x,
          y,
          "$ " +
            (parseFloat(doc.header.total) + parseFloat(doc.header.tax)).toLocaleString(2),
          "right"
        );
        // y += 2;
        // pdf.line(20, y, 200, y);
        y += LS;
        y += LS;

        temY = y;
        pdf.setFontSize(8);
        var note = pdf.splitTextToSize(
          "El presente documento presta merito ejecutivo y se asemeja a una letra de cambio.",
          85
        );
        pdf.text(115, y, note);

        pdf.text(20, y, "Observaciones");
        note = pdf.splitTextToSize(doc.header.note, 85);
        y += LS;
        pdf.text(20, y, note);
        // pdf.setFontSize(8);
        y += LS;
        y += LS;
        y += LS;
        y += LS;
        // y += 2;
        // pdf.line(40, y, 120, y);
        y += LS;

        pdf.text(115, y, "ACEPTO : " + doc.header.party_name);
        y += LS;
        pdf.text(
          115,
          y,
          this.settings["type_document_identifications"].find(
            (item) => item.id == doc.header.party_type_id
          ).name +
            ": " +
            doc.header.party_identification_id
        );
        pdf.roundedRect(112, temY - 5, 93, y - temY + 8, 1, 1);

        pdf.roundedRect(15, temY - 5, 93, y - temY + 8, 1, 1);

        pdf.setTextColor(0, 183, 0);
        var today = new Date();
        pdf.text(10, 100, "Date Printed : " + today.toLocaleString(), null, 90);
        var out = pdf.output();
        var url = "data:application/pdf;base64," + btoa(out);
        this.pdfFile = url;
        //this.pdfFile = pdf.output('datauri');

        var pdfName = doc.header.doc_code;
        pdf.save(pdfName + "_" + doc.header.party_name + ".pdf");
      }
    },
    e_invoice() {
      var custumer = this.item.buyer;
      var itms = this.item.items;
      var doc = this.item.document;

      var eInv = {
        number: doc.invoice.split("-")[1],
        sync: false,
        type_document_id: 1,
        customer: {
          identification_number: custumer.PartyIdentification,
          name: custumer.PartyName,
          municipality_id: 126,
          email: custumer.client_email,
        },
        legal_monetary_totals: {
          line_extension_amount: 0,
          tax_exclusive_amount: 0,
          tax_inclusive_amount: 0,
          allowance_total_amount: 0,
          charge_total_amount: 0,
          payable_amount: 0,
        },
        invoice_lines: [],
      };

      var tax_value = 1.19;
      var taxvalue = 19.0;

      itms.forEach((item) => {
        item.quantity = parseFloat(item.quantity);
        //Valor del artículo o servicio
        item.price_amount = (parseFloat(item.price) / tax_value).toFixed(2);
        item.discount = parseFloat(item.discount).toFixed(2);
        item.tax = (
          (parseFloat(item.price_amount) * parseFloat(item.quantity) * taxvalue) /
          100
        ).toFixed(2);

        //Valor total de la línea (Cantidad x Precio Unidad menos descuentos más recargos que apliquen para la línea)
        item.line_extension_amount = (
          parseFloat(item.price_amount) * parseFloat(item.quantity)
        ).toFixed(2);
      });

      itms.forEach((item) => {
        var itm = {
          description: item.description,
          unit_measure_id: 642,
          code: item.ean,
          type_item_identification_id: 3,
          base_quantity: item.quantity.toFixed(6),
          invoiced_quantity: item.quantity.toFixed(6),
          price_amount: item.price_amount,
          line_extension_amount: item.line_extension_amount,
        };
        if (parseFloat(item.discount) > 0) {
          itm.allowance_charges = [
            {
              charge_indicator: false,
              allowance_charge_reason: "Discount",
              amount: item.discount,
              base_amount: (
                parseFloat(item.price_amount) * parseFloat(item.quantity)
              ).toFixed(2),
            },
          ];
          eInv.legal_monetary_totals.allowance_total_amount += parseFloat(item.discount);
        }

        itm.tax_totals = [
          {
            tax_id: 1,
            tax_amount: item.tax,
            taxable_amount: (
              parseFloat(item.price_amount) * parseFloat(item.quantity)
            ).toFixed(2),
            percent: taxvalue.toFixed(2),
          },
        ];

        eInv.legal_monetary_totals.line_extension_amount += parseFloat(
          itm.line_extension_amount
        ); //Valores totales aplicables a la factura
        eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
          item.line_extension_amount
        ); // Total Valor Bruto (Antes de tributos)
        eInv.legal_monetary_totals.tax_inclusive_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.legal_monetary_totals.charge_total_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.legal_monetary_totals.payable_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.invoice_lines.push(itm);
      });

      var payments = this.item.payments;
      var bags = payments.find((item) => item.tipo == "ICB ley 1819 de 2016");
      if (bags) {
        bags.quantity = parseFloat(bags.valor) / 51;
        bags.tax = 51;
        var itmBags = {
          description: "Bolsas",
          unit_measure_id: 886,
          code: "000000000000",
          type_item_identification_id: 3,
          base_quantity: "1.000000",
          invoiced_quantity: bags.quantity.toFixed(6),
          price_amount: parseFloat(bags.valor).toFixed(2),
          line_extension_amount: "0.00",
          reference_price_id: 1,
          tax_totals: [
            {
              tax_id: 10,
              tax_amount: parseFloat(bags.valor).toFixed(2),
              taxable_amount: parseFloat(bags.quantity).toFixed(2),
              unit_measure_id: 886,
              per_unit_amount: bags.tax.toFixed(2),
              base_unit_measure: bags.quantity.toFixed(6),
            },
          ],
          //  parseFloat(bags.quantity).toFixed(2),
        };
        //
        eInv.legal_monetary_totals.line_extension_amount += parseFloat(
          itmBags.line_extension_amount
        ); //Valores totales aplicables a la factura
        eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
          itmBags.line_extension_amount
        ); // Total Valor Bruto (Antes de tributos)
        eInv.legal_monetary_totals.tax_inclusive_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.valor);
        eInv.legal_monetary_totals.charge_total_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.valor);
        eInv.legal_monetary_totals.payable_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.valor);
        eInv.invoice_lines.push(itmBags);
      }

      eInv.legal_monetary_totals.line_extension_amount = eInv.legal_monetary_totals.line_extension_amount.toFixed(
        2
      ); //Valores totales aplicables a la factura
      eInv.legal_monetary_totals.tax_exclusive_amount = eInv.legal_monetary_totals.tax_exclusive_amount.toFixed(
        2
      ); // Total Valor Bruto (Antes de tributos)
      eInv.legal_monetary_totals.tax_inclusive_amount = eInv.legal_monetary_totals.tax_inclusive_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.allowance_total_amount = eInv.legal_monetary_totals.allowance_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.charge_total_amount = eInv.legal_monetary_totals.charge_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.payable_amount = eInv.legal_monetary_totals.payable_amount.toFixed(
        2
      );
      this.put_invoice(eInv);
    },
    put_invoice(data) {
      this.loading_status = true;
      var qry = {
        method: "POST",
        nit: this.$store.state.seller.store_business_id,
        data: data,
        store: this.item.document.store,
        issueDate: this.item.document.issueDate,
        did: this.item.document.doc_number,
      };
      this.loading_status = true;
      fe_ws("post_invoice", qry, (data) => {
        this.loading_status = false;
        //console.log(JSON.parse(data.data));
        this.dian_dialog = true;
        this.dian_response = JSON.parse(data.data);
        this.loading_status = false;
      });
    },
    put_credit_note(data) {
      this.loading_status = true;
      var qry = {
        method: "POST",
        nit: this.$store.state.seller.store_business_id,
        data: data,
        store: this.item.document.store,
        issueDate: this.item.document.issueDate,
        did: this.item.document.doc_number,
      };
      this.loading_status = true;
      fe_ws("post_credit_note", qry, (data) => {
        this.loading_status = false;
        //console.log(JSON.parse(data.data));
        this.dian_dialog = true;
        this.dian_response = JSON.parse(data.data);
        this.loading_status = false;
      });
    },
    get_e_doc() {
      var doc = {
        store: this.item.document.store,
        issueDate: this.item.document.issueDate,
        doc_number: this.item.document.doc_number,
      };
      //console.log(doc);
      webserver("get_e_doc", doc, (data) => {
        //console.log(data);
        //data.doc.doc_type = "NC";
        //data.doc.doc_number = null;
        this.put_e_doc(data);
      });
    },
    put_e_doc(e) {
      var doc = {
        store: this.item.document.store,
        data: e,
        action: "cancel",
      };
      webserver("put_e_doc", doc, () => {
        //this.e_credit_note(data);
        //console.log(data);
        this.loading_vendors = false;
      });
    },
    e_credit_note(e) {
      //console.log(e);
      var doc = e.doc;
      var itms = e.items;
      //var payments = e.payments;
      doc.city_code = 126;

      var eInv = {
        discrepancy_response: {
          correction_concept_id: 2,
        },
        billing_reference: {
          number: doc.invoice.replace("-", ""),
          uuid: doc.cufe,
          issue_date: doc.issuedate,
        },
        number: doc.doc_number.split("-")[2],
        sync: true,
        type_document_id: 5,
        customer: {
          identification_number: doc.PartyIdentification,
          name: doc.PartyName,
          municipality_id: doc.city_code,
          email: doc.PartyEmail,
        },
        legal_monetary_totals: {
          line_extension_amount: 0,
          tax_exclusive_amount: 0,
          tax_inclusive_amount: 0,
          allowance_total_amount: 0,
          charge_total_amount: 0,
          payable_amount: 0,
        },
        credit_note_lines: [],
      };

      var tax_value = 1.19;
      var taxvalue = 19.0;

      itms.forEach((item) => {
        item.item_quantity = parseFloat(item.item_quantity);
        //Valor del artículo o servicio
        item.price_amount = (parseFloat(item.item_price) / tax_value).toFixed(2);
        item.discount = (
          (parseFloat(item.item_discount) / tax_value).toFixed(2) *
          parseFloat(item.item_quantity)
        ).toFixed(2);
        item.tax = (
          (parseFloat(item.price_amount) * parseFloat(item.item_quantity) * taxvalue) /
          100
        ).toFixed(2);
        //Valor total de la línea (Cantidad x Precio Unidad menos descuentos más recargos que apliquen para la línea)
        item.line_extension_amount = (
          parseFloat(item.price_amount) * parseFloat(item.item_quantity)
        ).toFixed(2);
      });

      itms.forEach((item) => {
        var itm = {
          description: item.item_concept,
          unit_measure_id: 642,
          code: item.item_code,
          type_item_identification_id: 3,
          base_quantity: item.item_quantity.toFixed(6),
          invoiced_quantity: item.item_quantity.toFixed(6),
          price_amount: item.price_amount,
          line_extension_amount: item.line_extension_amount,
        };
        if (parseFloat(item.item_discount) > 0) {
          itm.allowance_charges = [
            {
              charge_indicator: false,
              allowance_charge_reason: "Discount",
              amount: item.item_discount,
              base_amount: (
                parseFloat(item.price_amount) * parseFloat(item.item_quantity)
              ).toFixed(2),
            },
          ];
          eInv.legal_monetary_totals.allowance_total_amount += parseFloat(
            item.item_discount
          );
        }
        itm.tax_totals = [
          {
            tax_id: 1,
            tax_amount: item.tax,
            taxable_amount: (
              parseFloat(item.price_amount) * parseFloat(item.item_quantity)
            ).toFixed(2),
            percent: taxvalue.toFixed(2),
          },
        ];
        eInv.legal_monetary_totals.line_extension_amount += parseFloat(
          itm.line_extension_amount
        ); //Valores totales aplicables a la factura
        eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
          item.line_extension_amount
        ); // Total Valor Bruto (Antes de tributos)
        eInv.legal_monetary_totals.tax_inclusive_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.legal_monetary_totals.charge_total_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.legal_monetary_totals.payable_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.credit_note_lines.push(itm);
      });

      var payments = this.item.payments;
      var bags = payments.find((item) => item.payment_concept == "ICB ley 1819 de 2016");
      if (bags) {
        bags.quantity = parseFloat(bags.payment_value) / 51;
        bags.tax = 51;
        var itmBags = {
          description: "Bolsas",
          unit_measure_id: 886,
          code: "000000000000",
          type_item_identification_id: 3,
          base_quantity: "1.000000",
          invoiced_quantity: bags.quantity.toFixed(6),
          price_amount: parseFloat(bags.payment_value).toFixed(2),
          line_extension_amount: "0.00",
          reference_price_id: 1,
          tax_totals: [
            {
              tax_id: 10,
              tax_amount: parseFloat(bags.payment_value).toFixed(2),
              taxable_amount: parseFloat(bags.quantity).toFixed(2),
              unit_measure_id: 886,
              per_unit_amount: bags.tax.toFixed(2),
              base_unit_measure: "1.000000",
            },
          ],
          //  parseFloat(bags.quantity).toFixed(2),
        };
        //
        eInv.legal_monetary_totals.line_extension_amount += parseFloat(
          itmBags.line_extension_amount
        ); //Valores totales aplicables a la factura
        eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
          itmBags.line_extension_amount
        ); // Total Valor Bruto (Antes de tributos)
        eInv.legal_monetary_totals.tax_inclusive_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
        eInv.legal_monetary_totals.charge_total_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
        eInv.legal_monetary_totals.payable_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
        eInv.invoice_lines.push(itmBags);
      }

      eInv.legal_monetary_totals.line_extension_amount = eInv.legal_monetary_totals.line_extension_amount.toFixed(
        2
      ); //Valores totales aplicables a la factura
      eInv.legal_monetary_totals.tax_exclusive_amount = eInv.legal_monetary_totals.tax_exclusive_amount.toFixed(
        2
      ); // Total Valor Bruto (Antes de tributos)
      eInv.legal_monetary_totals.tax_inclusive_amount = eInv.legal_monetary_totals.tax_inclusive_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.allowance_total_amount = eInv.legal_monetary_totals.allowance_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.charge_total_amount = eInv.legal_monetary_totals.charge_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.payable_amount = eInv.legal_monetary_totals.payable_amount.toFixed(
        2
      );
      //console.log(eInv);
      this.put_credit_note(eInv);
    },
  },
  watch: {
    item: function (e) {
      this.printCanvas(e);
    },
  },
};
</script>
