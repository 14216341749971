<template>
  <v-container>
    <v-bottom-navigation v-if="$vuetify.breakpoint.xsOnly" hide-on-scroll app>
      <v-toolbar elevation="6">
        <v-row class="pa-2">
          <v-col
            cols="7"
            v-if="
              $store.state.profile.user_access === 'Sysop' ||
              $store.state.profile.user_access === 'Admin'
            "
          >
            <v-select
              v-model="doc_type"
              hide-details="auto"
              item-text="label"
              item-value="value"
              :items="docType"
              label="Documento"
              outlined
              dense
              @change="get_oders()"
              return-object
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="status"
              :items="status_lst"
              label="Estado"
              outlined
              hide-details="auto"
              dense
              @change="get_oders()"
            />
          </v-col>
        </v-row>
      </v-toolbar>
    </v-bottom-navigation>
    <v-data-table
      calculate-widths
      :headers="header_orders[$vuetify.breakpoint.xsOnly]"
      :items="orders"
      :items-per-page="15"
      item-key="index"
      sort-by="issue_date"
      sort-desc
      mobile-breakpoint="0"
      class="table-cursor elevation-2"
      loading-text="Cargando ..."
      @click:row="view_doc"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ doc_type.label }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            x-small
            color="info"
            @click="filterDialog = !filterDialog"
            class="mr-3"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
          <v-btn fab x-small color="success" v-if="doc_type" @click="newDoc">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="pa-2" v-if="filterDialog">
          <v-col
            cols="12"
            md="3"
            v-if="
              $store.state.profile.user_access === 'Sysop' ||
              $store.state.profile.user_access === 'Admin'
            "
          >
            <v-select
              v-model="doc_type"
              hide-details="auto"
              item-text="label"
              item-value="value"
              :items="docType"
              label="Documento"
              outlined
              dense
              @change="get_oders()"
              return-object
            />
          </v-col>

          <v-col cols="6" md="3">
            <v-text-field
              v-model="issueDateFrom"
              label="Desde"
              hide-details="auto"
              required
              outlined
              type="date"
              dense
              @change="get_oders()"
            />
          </v-col>

          <v-col cols="6" md="3">
            <v-text-field
              v-model="issueDateTo"
              label="Hasta"
              hide-details="auto"
              required
              outlined
              type="date"
              dense
              @change="get_oders()"
            />
          </v-col>
          <v-col cols="12" md="3" v-if="!$vuetify.breakpoint.xsOnly">
            <v-select
              v-model="status"
              :items="status_lst"
              label="Estado"
              outlined
              hide-details="auto"
              dense
              @change="get_oders()"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="$store.state.profile.user_access !== 'Cliente'">
            <v-text-field
              class="centered-input"
              hide-details="auto"
              v-model="ClientName"
              label="Cliente"
              outlined
              required
              dense
              clearable
              append-icon="mdi-magnify"
              @click:append="get_oders()"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.party_name`]="{ item }" class="text-right">
        {{ item.party_name }}<br />
        <small v-if="$vuetify.breakpoint.xsOnly"
          >{{ item.delivery_date }}@{{ item.delivery_time }}</small
        >
      </template>
      <template v-slot:[`item.delivery_date`]="{ item }" class="text-right">
        {{ item.delivery_date }}<br />
        <small>{{ item.delivery_time }}</small>
      </template>
      <template v-slot:[`item.status`]="{ item }" class="text-right">
        <v-chip color="red" dark outlined small v-if="item.status === 'PENDIENTE'">
          {{ item.status }}
        </v-chip>
        <small v-else>{{ item.status }}</small>
      </template>
      <template v-slot:[`item.total`]="{ item }" class="text-right">
        {{ "$ " + parseFloat(item.total).toLocaleString() }}<br />
        <v-chip
          color="red"
          dark
          outlined
          small
          v-if="$vuetify.breakpoint.xsOnly && item.status === 'PENDIENTE'"
        >
          {{ item.status }}
        </v-chip>
        <small v-else>{{ item.status }}</small>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="view_doc(item)"> mdi-magnify </v-icon>
      </template>
    </v-data-table>

    <docViewer
      :item="pdf"
      :dialog="dialog"
      @close="dialog = false"
      @refresh="refresh()"
    />
  </v-container>
</template>

<script>
import { webserver, getToday, getIssueDate } from "../../services/webserver.js";
import docViewer from "../../components/doc_viewer.vue";

import createItem from "../../utils/createItem.js";
import createClient from "../../utils/createParty.js";
import docs_lst from "../../utils/docs.js";

export default {
  components: { docViewer },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
    this.company = this.$store.getters.company;

    if (this.$route.params.docType) {
      this.doc_type = this.docType.find(
        (item) => item.value == this.$route.params.docType
      );
      //console.log("this.docType", this.docType, this.doc_type);
    }
    this.get_oders();
  },
  data: () => ({
    dialog: false,
    filterDialog: false,
    best_height: "100%",
    best_width: "500",
    categories: [],
    doc_type: { label: "Orden de Compra", value: "OR" },
    docType: docs_lst().docType,
    status_lst: ["", "PENDIENTE", "DESPACHADO", "ENTREGADO", "CANCELADO"],
    loading_status: false,
    smart_categories: [],
    smart_lst_selected: [],
    smart_lst: [],
    selectedItem: 0,
    tab: "orders",
    store_dialog: false,
    itemSelected: createItem(),
    add_dialog: false,
    addItm: false,
    Scart: false,
    sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
    clientDialog: false,
    orders: [],
    ClientName: "",
    header_orders: {
      false: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "issue_date",
          dataType: "date",
        },
        {
          text: "Orden",
          align: "start",
          value: "doc_code",
          dataType: "text",
        },
        {
          text: "Nombre",
          align: "start",
          value: "party_name",
          dataType: "text",
        },

        {
          text: "Status",
          align: "start",
          value: "status",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
        },
      ],
      true: [
        {
          text: "Nombre",
          align: "start",
          value: "party_name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
        },
      ],
    },
    issueDateFrom: getIssueDate(60),
    issueDateTo: getToday(),
    status: "",
    pdf: {
      header: { cufe: "" },
      items: [],
      payments: [],
      store: createClient(),
    },
  }),
  methods: {
    newDoc() {
      this.$router.push({
        name: "Doc_e",
        params: { docType: this.doc_type.value, edit: true },
      });
    },
    get_oders() {
      var qry = {
        table: "docs",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
          { field: "store", operator: "=", value: this.company.code },
          { field: "date(issue_date)", operator: ">=", value: this.issueDateFrom },
          { field: "date(issue_date)", operator: "<=", value: this.issueDateTo },
          {
            field: "status",
            operator: " IN ",
            value: "'','PENDIENTE', 'DESPACHADO', 'ENTREGADO', 'CANCELADO'",
          },
        ],
      };
      if (this.status) {
        qry.filters.push({ field: "status", operator: "=", value: this.status });
      }
      if (this.doc_type) {
        qry.filters.push({
          field: "doc_type",
          operator: "=",
          value: this.doc_type.value,
        });
      }
      if (this.ClientName) {
        qry.filters.push({
          field: "party_name",
          operator: " LIKE ",
          value: this.ClientName,
        });
      }
      //console.log("qry", this.$store.state.profile);
      if (this.$store.state.profile.user_access == "Cliente") {
        qry.filters.push({
          field: "party_identification_id",
          operator: "=",
          value: this.$store.state.profile.user_id,
        });
      }

      if (this.$store.state.profile.user_access == "Ventas") {
        qry.filters.push({
          field: "agent",
          operator: "=",
          value: this.$store.state.profile.code,
        });
      }

      this.$store.dispatch("setToast", {
        timeout_error: -1,
        dialog: true,
        text: "Procesando",
        color: "green",
      });
      webserver("get_doc_status", qry, (data) => {
        this.$store.dispatch("setToast", {
          timeout_error: 3000,
          dialog: false,
          text: "Procesando",
          color: "green",
        });
        this.dialog = false;
        this.orders = data;
      });
    },
    view_doc(e) {
      //console.log(e);
      // if (e.doc_type == 'OR') {
      // this.$router.push({
      //   name: "Doc_e",
      //   params: { did: e.doc_code },
      // });
      // } else  {

      //}
      this.get_doc(e.doc_code);
      //this.$router.push({ name: "Doc_e", params: { did: e.doc_code, edit: false } });
    },
    get_doc(doc_code) {
      this.loading_status = true;
      var qry = {
        table: "docs",
        doc_code: doc_code,
      };
      webserver("get_doc", qry, (data) => {
        //console.log("get_doc", data);
        this.pdf = data;
        this.dialog = true;
        this.loading_status = false;
      });
    },

    SendWhatsapp() {
      this.loading_status = true;
      var qry = {
        from: "+573236866116",
        to: "+16475503859",
        msg: "NGM te informa que has recibido la Orden de Compra #",
      };
      //console.log(qry);
      webserver("send_whatsapp", qry, (data) => {
        this.loading_status = false;
        console.log("send_whatsapp", data);
      });
    },
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_width = window.innerWidth;
      } else {
        this.best_width = window.innerWidth / 2;
      }
    },
  },

  watch: {},
};
</script>

<style></style>
