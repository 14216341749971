var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.$vuetify.breakpoint.xsOnly)?_c('v-bottom-navigation',{attrs:{"hide-on-scroll":"","app":""}},[_c('v-toolbar',{attrs:{"elevation":"6"}},[_c('v-row',{staticClass:"pa-2"},[(
            _vm.$store.state.profile.user_access === 'Sysop' ||
            _vm.$store.state.profile.user_access === 'Admin'
          )?_c('v-col',{attrs:{"cols":"7"}},[_c('v-select',{attrs:{"hide-details":"auto","item-text":"label","item-value":"value","items":_vm.docType,"label":"Documento","outlined":"","dense":"","return-object":""},on:{"change":function($event){return _vm.get_oders()}},model:{value:(_vm.doc_type),callback:function ($$v) {_vm.doc_type=$$v},expression:"doc_type"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.status_lst,"label":"Estado","outlined":"","hide-details":"auto","dense":""},on:{"change":function($event){return _vm.get_oders()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"table-cursor elevation-2",attrs:{"calculate-widths":"","headers":_vm.header_orders[_vm.$vuetify.breakpoint.xsOnly],"items":_vm.orders,"items-per-page":15,"item-key":"index","sort-by":"issue_date","sort-desc":"","mobile-breakpoint":"0","loading-text":"Cargando ..."},on:{"click:row":_vm.view_doc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.doc_type.label)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"fab":"","x-small":"","color":"info"},on:{"click":function($event){_vm.filterDialog = !_vm.filterDialog}}},[_c('v-icon',[_vm._v("mdi-filter")])],1),(_vm.doc_type)?_c('v-btn',{attrs:{"fab":"","x-small":"","color":"success"},on:{"click":_vm.newDoc}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),(_vm.filterDialog)?_c('v-row',{staticClass:"pa-2"},[(
            _vm.$store.state.profile.user_access === 'Sysop' ||
            _vm.$store.state.profile.user_access === 'Admin'
          )?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"hide-details":"auto","item-text":"label","item-value":"value","items":_vm.docType,"label":"Documento","outlined":"","dense":"","return-object":""},on:{"change":function($event){return _vm.get_oders()}},model:{value:(_vm.doc_type),callback:function ($$v) {_vm.doc_type=$$v},expression:"doc_type"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Desde","hide-details":"auto","required":"","outlined":"","type":"date","dense":""},on:{"change":function($event){return _vm.get_oders()}},model:{value:(_vm.issueDateFrom),callback:function ($$v) {_vm.issueDateFrom=$$v},expression:"issueDateFrom"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Hasta","hide-details":"auto","required":"","outlined":"","type":"date","dense":""},on:{"change":function($event){return _vm.get_oders()}},model:{value:(_vm.issueDateTo),callback:function ($$v) {_vm.issueDateTo=$$v},expression:"issueDateTo"}})],1),(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.status_lst,"label":"Estado","outlined":"","hide-details":"auto","dense":""},on:{"change":function($event){return _vm.get_oders()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1):_vm._e(),(_vm.$store.state.profile.user_access !== 'Cliente')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"centered-input",attrs:{"hide-details":"auto","label":"Cliente","outlined":"","required":"","dense":"","clearable":"","append-icon":"mdi-magnify"},on:{"click:append":function($event){return _vm.get_oders()}},model:{value:(_vm.ClientName),callback:function ($$v) {_vm.ClientName=$$v},expression:"ClientName"}})],1):_vm._e()],1):_vm._e()]},proxy:true},{key:"item.party_name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.party_name)),_c('br'),(_vm.$vuetify.breakpoint.xsOnly)?_c('small',[_vm._v(_vm._s(item.delivery_date)+"@"+_vm._s(item.delivery_time))]):_vm._e()]}},{key:"item.delivery_date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.delivery_date)),_c('br'),_c('small',[_vm._v(_vm._s(item.delivery_time))])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.status === 'PENDIENTE')?_c('v-chip',{attrs:{"color":"red","dark":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('small',[_vm._v(_vm._s(item.status))])]}},{key:"item.total",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.total).toLocaleString())),_c('br'),(_vm.$vuetify.breakpoint.xsOnly && item.status === 'PENDIENTE')?_c('v-chip',{attrs:{"color":"red","dark":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('small',[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.view_doc(item)}}},[_vm._v(" mdi-magnify ")])]}}],null,true)}),_c('docViewer',{attrs:{"item":_vm.pdf,"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }